import React, { ReactElement, useEffect } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { MantineProvider } from "@mantine/core";
import { Notifications } from "@mantine/notifications";
import { ConfigProvider, App as AntdApp } from "antd";
import localeGermany from "antd/lib/locale/de_DE";
import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import { InteractionStatus } from "@azure/msal-browser";
import { useQueryClient } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { ModalsProvider } from "@mantine/modals";
import "dayjs/locale/de";
import { DatesProvider } from "@mantine/dates";
import { jelbiDashboardTheme } from "./styles/theme";
import customAntdTheme from "./styles/antd-custom-theme";
import {
	UserRole,
	defaults as dashboardApi,
} from "./utilities/api/jelbi-dashboard-api";
import ProtectedRoute from "./components/ProtectedRoute";
import StationCollectionMap from "./components/StationCollectionMap/StationCollectionMap";
import UserProfile from "./components/UserProfile";
import "./styles/global.scss";
import "leaflet/dist/leaflet.css";
import useGetAccessToken from "./utilities/client/hooks/useGetAccessToken";
import StationIssues from "./components/StationIssues/StationIssues";
import RoleBasedRender from "./components/RoleBasedRender";
import "@mantine/core/styles.layer.css";
import EditStation from "./components/StationForm/EditStation";
import AddStation from "./components/StationForm/AddStation";
import "@mantine/dates/styles.css";
import "@mantine/notifications/styles.css";
import "@mantine/charts/styles.css";
import "yet-another-react-lightbox/styles.css";
import Msps from "./components/Msps/Msps";
import MspAddEditForm from "./components/Msps/MspAddEditForm/MspAddEditForm";
import EditMsp from "./components/Msps/MspAddEditForm/EditMsp";
import "mantine-datatable/styles.layer.css";
import AddIssue from "./components/StationIssues/AddIssue/AddIssue";
import Inspections from "./components/Inspections/Inspections";
import AddInspection from "./components/Inspections/AddInspection/AddInspection";
import ReadInspection from "./components/Inspections/ReadInspection/ReadInspection";

function App(): ReactElement {
	const { instance: msalInstance, inProgress: msalInProgress } = useMsal();
	const isAuthenticated = useIsAuthenticated();
	// call login redirect if user isn´t authenticated and auth provider is not already doing something
	useEffect(() => {
		if (msalInProgress === InteractionStatus.None && !isAuthenticated) {
			msalInstance.loginRedirect();
		}
	}, [isAuthenticated, msalInProgress, msalInstance]);

	const accessToken = useGetAccessToken();
	dashboardApi.headers = {
		Authorization: `Bearer ${accessToken}`,
	};
	dashboardApi.baseUrl = process.env.REACT_APP_API_BASE_URL ?? "";

	// do not send requests to Jelbi DB API without access token
	const queryClient = useQueryClient();
	queryClient.setDefaultOptions({ queries: { enabled: accessToken !== null } });

	return (
		<>
			<MantineProvider forceColorScheme="light" theme={jelbiDashboardTheme}>
				<Notifications
					// above Navbar and modals
					zIndex={1005}
				/>
				<ModalsProvider
					// above Mantine Modal component
					modalProps={{ zIndex: 1004 }}
				>
					<DatesProvider settings={{ locale: "de", consistentWeeks: true }}>
						<ConfigProvider locale={localeGermany} theme={customAntdTheme}>
							<AntdApp>
								<BrowserRouter>
									<Routes>
										<Route
											path="/issues/:issueId?"
											element={
												<ProtectedRoute>
													<StationIssues />
												</ProtectedRoute>
											}
										/>
										<Route
											path="/issues/add"
											element={
												<ProtectedRoute>
													<AddIssue />
												</ProtectedRoute>
											}
										/>
										<Route
											path="/inspections"
											element={
												<ProtectedRoute>
													<RoleBasedRender
														allowedRoles={[UserRole.Admin, UserRole.Ranger]}
													>
														<Inspections />
													</RoleBasedRender>
												</ProtectedRoute>
											}
										/>
										<Route
											path="/inspections/add"
											element={
												<ProtectedRoute>
													<RoleBasedRender
														allowedRoles={[UserRole.Admin, UserRole.Ranger]}
													>
														<AddInspection />
													</RoleBasedRender>
												</ProtectedRoute>
											}
										/>
										<Route
											path="/inspections/:inspectionId"
											element={
												<ProtectedRoute>
													<RoleBasedRender
														allowedRoles={[UserRole.Admin, UserRole.Ranger]}
													>
														<ReadInspection />
													</RoleBasedRender>
												</ProtectedRoute>
											}
										/>
										<Route
											path="/profile"
											element={
												<ProtectedRoute>
													<UserProfile />
												</ProtectedRoute>
											}
										/>
										<Route
											path="/stations/:stationId?"
											element={
												<ProtectedRoute>
													<StationCollectionMap />
												</ProtectedRoute>
											}
										/>
										<Route
											path="/stations/:stationId/edit"
											element={
												<ProtectedRoute>
													<RoleBasedRender allowedRoles={[UserRole.Admin]}>
														<EditStation />
													</RoleBasedRender>
												</ProtectedRoute>
											}
										/>
										<Route
											path="/stations/add"
											element={
												<ProtectedRoute>
													<RoleBasedRender allowedRoles={[UserRole.Admin]}>
														<AddStation />
													</RoleBasedRender>
												</ProtectedRoute>
											}
										/>
										<Route
											path="/msps"
											element={
												<ProtectedRoute>
													<RoleBasedRender allowedRoles={[UserRole.Admin]}>
														<Msps />
													</RoleBasedRender>
												</ProtectedRoute>
											}
										/>
										<Route
											path="/msps/add"
											element={
												<ProtectedRoute>
													<RoleBasedRender allowedRoles={[UserRole.Admin]}>
														<MspAddEditForm />
													</RoleBasedRender>
												</ProtectedRoute>
											}
										/>
										<Route
											path="/msps/:mspId/edit"
											element={
												<ProtectedRoute>
													<RoleBasedRender allowedRoles={[UserRole.Admin]}>
														<EditMsp />
													</RoleBasedRender>
												</ProtectedRoute>
											}
										/>
										{/* the "/" path must be the last matching route, any following route will never be served */}
										<Route
											path="/"
											element={
												<ProtectedRoute>
													<StationCollectionMap />
												</ProtectedRoute>
											}
										/>
									</Routes>
								</BrowserRouter>
							</AntdApp>
						</ConfigProvider>
					</DatesProvider>
				</ModalsProvider>
			</MantineProvider>
			<ReactQueryDevtools initialIsOpen={false} />
		</>
	);
}

export default App;
