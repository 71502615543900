// disable eslint for using props spreading as it's best practices in https://mantine.dev/form/use-form/
/* eslint-disable react/jsx-props-no-spreading */
import React, { Fragment, ReactElement, useEffect, useState } from "react";
import { isNotEmpty, useForm } from "@mantine/form";
import {
	Button,
	Divider,
	Flex,
	InputLabel,
	Stack,
	Text,
	Title,
} from "@mantine/core";
import { IconCheck } from "@tabler/icons-react";
import { ok } from "@oazapfts/runtime";
import { Link, useLocation, useNavigate, Location } from "react-router-dom";
import { format } from "date-fns";
import { modals } from "@mantine/modals";
import classes from "./AddInspection.module.scss";
import useGetUserRoles from "../../../utilities/client/hooks/useGetUserRoles";
import { getHighestUserRole } from "../../../utilities/client/roles.util";
import {
	UserRole,
	postInspection,
} from "../../../utilities/api/jelbi-dashboard-api";
import useIsMobileView from "../../../utilities/client/hooks/useIsMobileView";
import InspectionEquipmentInput from "./InspectionEquipmentInput";
import notifications from "./AddInspection.notifications";
import PhotoUpload, { Photo } from "../../PhotoUpload/PhotoUpload";
import DashboardSelectStation from "../../DashboardSelectStation/DashboardSelectStation";
import initialValues from "./utils/initialValues";
import {
	InspectionLocationState,
	InspectionUseFormReturnType,
} from "./utils/add-inspection-form.types";

function AddInspection(): ReactElement {
	const isMobile = useIsMobileView();
	const navigate = useNavigate();
	const { state }: Location<InspectionLocationState> = useLocation();

	const userRoles = useGetUserRoles();

	const [isSaveButtonEnabled, setIsSaveButtonEnabled] = useState(false);

	const form: InspectionUseFormReturnType = useForm({
		mode: "uncontrolled",
		validateInputOnChange: true,
		initialValues: initialValues(state),
		transformValues: (values) => ({
			stationId: values.stationId,
			createdAt: values.createdAt.toISOString(),
			createdBy: getHighestUserRole(userRoles) || UserRole.Member, // TODO: handle default
			photoIds: values.photos.map(({ id }) => id),
			issues: values.issues,
		}),
		validate: {
			stationId: isNotEmpty("Bitte wähle einen Standort aus."),
			photos: (value, values) =>
				values.photos.length < 1 ||
				values.photos.length > 2 ||
				value.some(({ isProcessing }: Photo) => isProcessing === true),
			issues: (value, values) =>
				!values.detectedIssues.every((detectedIssue) =>
					value.find(({ equipment }) => equipment === detectedIssue)
				),
		},
	});

	useEffect(() => {
		if (form.isValid()) {
			setIsSaveButtonEnabled(true);
		} else {
			setIsSaveButtonEnabled(false);
		}
	}, [form]);

	const openAbortModal = () => {
		if (form.isDirty()) {
			modals.openConfirmModal({
				children: <Text>Begehung abbrechen und alle Daten verwerfen?</Text>,
				labels: {
					confirm: "Weiter",
					cancel: "Zurück",
				},
				confirmProps: { color: "red", fullWidth: isMobile },
				cancelProps: { fullWidth: isMobile },
				groupProps: {
					style: { flexDirection: isMobile ? "column-reverse" : "row" },
				},
				onConfirm: () => navigate("/inspections?page=1"),
				withCloseButton: false,
			});
		} else {
			navigate("/inspections?page=1");
		}
	};

	return (
		<Stack className={classes.container}>
			<Title order={3}>Neue Begehung</Title>
			<form
				onSubmit={form.onSubmit((values) => {
					ok(postInspection(values))
						.then(() => {
							notifications.showAddInspectionSuccessfulNotification();
							navigate(-1);
						})
						.catch(() => notifications.showAddInspectionFailedNotification());
				})}
			>
				<Stack gap="xl">
					<Stack gap={0}>
						<InputLabel>Datum</InputLabel>
						<Text size="sm">
							{format(form.getValues().createdAt, "dd.MM.yyyy HH:mm")}
						</Text>
					</Stack>

					<DashboardSelectStation
						key={form.key("stationId")}
						formInputProps={form.getInputProps("stationId")}
						label="Standort"
						withAsterisk
						placeholder="Bitte wähle einen Standort aus"
					/>

					<InspectionEquipmentInput form={form} />

					<PhotoUpload
						key={form.key("photos")}
						{...form.getInputProps("photos")}
						maxItems={2}
						required
					/>

					<Stack gap="xs">
						<InputLabel>Problemmeldungen</InputLabel>
						<Text c="dimmed" size="sm">
							Bitte ergänze die Problemmeldung(en) mit Informationen.
						</Text>
						<Text c="dimmed" size="sm">
							Vollständig ausgefüllte Meldungen sind grün markiert.
						</Text>
						{form.getValues().detectedIssues.map((detectedIssue) => (
							<Fragment key={`${detectedIssue}-link`}>
								<Divider />
								<Link
									to="/issues/add"
									state={{
										currentInspection: form.getValues(),
										currentIssue: form
											.getValues()
											.issues.find(
												({ equipment }) => equipment === detectedIssue
											) ?? {
											equipment: detectedIssue,
											stationId: form.getValues().stationId,
											createdAt: form.getValues().createdAt,
										},
									}}
									replace
								>
									<Button
										styles={{
											root: { borderRadius: "0" },
										}}
										disabled={!form.getValues().stationId}
										variant="transparent"
										color="black"
										justify="start"
										fullWidth
										leftSection={
											form
												.getValues()
												.issues.find(
													({ equipment }) => equipment === detectedIssue
												) ? (
													<IconCheck color="green" />
											) : (
												<div style={{ width: 24, height: 24 }} />
											)
										}
									>
										{detectedIssue}
									</Button>
								</Link>
							</Fragment>
						))}
					</Stack>

					<Flex
						gap="md"
						justify="flex-end"
						align="flex-end"
						direction={isMobile ? "column-reverse" : "row"}
					>
						<Button
							variant="default"
							fullWidth={isMobile}
							onClick={openAbortModal}
						>
							Abbrechen
						</Button>
						<Button
							type="submit"
							fullWidth={isMobile}
							disabled={!isSaveButtonEnabled}
						>
							Speichern
						</Button>
					</Flex>
				</Stack>
			</form>
		</Stack>
	);
}

export default AddInspection;
