import React, { useState } from "react";
import { Overlay, Text, Image, Box, Center } from "@mantine/core";

type ImageWithOverlayProps = {
	src: string;
	size: number;
	onClickHandler: () => void;
};

function ImageWithOverlay({
	src,
	size,
	onClickHandler,
}: ImageWithOverlayProps) {
	const [hovered, setHovered] = useState(false);
	const isImageShown = src !== "";

	return (
		<Box
			style={{ position: "relative" }}
			onMouseEnter={() => setHovered(true)}
			onMouseLeave={() => setHovered(false)}
			onClick={onClickHandler}
			component="a"
		>
			{isImageShown ? (
				<>
					<Image src={src} w={size} h={size} />
					{hovered && (
						<Overlay>
							<Text c="gray.1" ta="center" lh={`${size}px`}>
								Vorschau
							</Text>
						</Overlay>
					)}
				</>
			) : (
				<Center bg="gray.2" h={size} w={size}>
					<Text c="dark.9" ta="center">
						Bild nicht verfügbar
					</Text>
				</Center>
			)}
		</Box>
	);
}
export default ImageWithOverlay;
