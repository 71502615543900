// disable eslint for using props spreading as it's best practices in https://mantine.dev/form/use-form/
/* eslint-disable react/jsx-props-no-spreading */
import React, { ReactElement } from "react";
import { Divider, Button, Stack, Grid } from "@mantine/core";
import { UseFormReturnType } from "@mantine/form";
import {
	StationFormValues,
	PlannedClosureFormType,
} from "./utils/station-form-types";
import useIsMobileView from "../../utilities/client/hooks/useIsMobileView";
import DashboardDateTimePicker from "../DashboardDateTimePicker/DashboardDateTimePicker";
import DashboardTextarea from "../DashboardTextarea/DashboardTextarea";

type PlannedClosuresListInputProps = {
	form: UseFormReturnType<StationFormValues>;
	isPlannedClosureEnabled: boolean;
};

function PlannedClosuresListInput({
	form,
	isPlannedClosureEnabled,
}: PlannedClosuresListInputProps): ReactElement {
	const isMobile = useIsMobileView();
	const { plannedClosuresList } = form.getValues();

	if (!plannedClosuresList) {
		return <div />;
	}

	return (
		<Stack>
			{plannedClosuresList.map((_: PlannedClosureFormType, index: number) => (
				// eslint-disable-next-line react/no-array-index-key
				<Grid key={`planned-closure-input-${index}`}>
					<Grid.Col span={12} order={1}>
						<Divider />
					</Grid.Col>

					<Grid.Col span={{ base: 12, lg: 6 }} order={2}>
						<DashboardDateTimePicker
							// eslint-disable-next-line react/no-array-index-key
							key={`plannedClosuresList.${index}.start`}
							formInputProps={form.getInputProps(
								`plannedClosuresList.${index}.start`
							)}
							label="Startdatum"
							withAsterisk
							minDate={new Date()}
							maxDate={plannedClosuresList[index].end}
							disabled={!isPlannedClosureEnabled}
						/>
					</Grid.Col>

					<Grid.Col span={{ base: 12, lg: 6 }} order={{ base: 4, lg: 3 }}>
						<DashboardTextarea
							// eslint-disable-next-line react/no-array-index-key
							key={`plannedClosuresList.${index}.comment`}
							formInputProps={form.getInputProps(
								`plannedClosuresList.${index}.comment`
							)}
							label="Kommentar zur Sperrzeit"
							placeholder="Gebe einen Kommentar zur Sperrzeit der Station an, wenn gewünscht"
							disabled={!isPlannedClosureEnabled}
						/>
					</Grid.Col>

					<Grid.Col span={{ base: 12, lg: 6 }} order={{ base: 3, lg: 4 }}>
						<DashboardDateTimePicker
							// eslint-disable-next-line react/no-array-index-key
							key={`plannedClosuresList.${index}.end`}
							formInputProps={form.getInputProps(
								`plannedClosuresList.${index}.end`
							)}
							label="Enddatum"
							withAsterisk
							minDate={plannedClosuresList[index].start || new Date()}
							disabled={!isPlannedClosureEnabled}
						/>
					</Grid.Col>

					<Grid.Col
						span={{ base: 12, lg: 6 }}
						order={5}
						style={{
							display: "flex",
							alignItems: "flex-end",
							justifyContent: "flex-end",
						}}
					>
						<Button
							variant="default"
							fullWidth={isMobile}
							onClick={() => form.removeListItem("plannedClosuresList", index)}
							disabled={!isPlannedClosureEnabled}
						>
							Sperrzeit löschen
						</Button>
					</Grid.Col>
				</Grid>
			))}
		</Stack>
	);
}

export default PlannedClosuresListInput;
