/* eslint-disable no-return-await */
import React, { ReactElement, useState } from "react";
import { useQuery } from "@tanstack/react-query";
import {
	Grid,
	InputLabel,
	LoadingOverlay,
	Stack,
	Title,
	Text,
	Box,
	Group,
	Button,
} from "@mantine/core";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Lightbox, { SlideImage } from "yet-another-react-lightbox";
import Download from "yet-another-react-lightbox/plugins/download";
import Zoom from "yet-another-react-lightbox/plugins/zoom";
import { ok } from "@oazapfts/runtime";
import { useDisclosure } from "@mantine/hooks";
import {
	getInspection,
	getPhoto,
} from "../../../utilities/api/jelbi-dashboard-api";
import { humanizedDateTimeFormat } from "../../../utilities/client/date-format";
import {
	getInspectionQueryKey,
	getPhotosQueryKey,
} from "../../../utilities/client/query-keys";
import Error from "../../Error/Error";
import classes from "./ReadInspection.module.scss";
import ImageWithOverlay from "../../ImageWithOverlay/ImageWithOverlay";
import useIsMobileView from "../../../utilities/client/hooks/useIsMobileView";

const IMAGE_SIZE = 100;

function ReadInspection(): ReactElement {
	const { inspectionId } = useParams();
	const isMobile = useIsMobileView();
	const [isImagePreviewerOpen, imagePreviewerHandler] = useDisclosure(false);
	const [selectedImageId, setSelectedImageId] = useState(0);
	const navigate = useNavigate();
	const { state: routerState } = useLocation();

	const inspectionTableRedirect = `/inspections?page=${routerState?.fromTablePage || 1}`;

	const { data: inspection, isPending } = useQuery({
		queryKey: getInspectionQueryKey(inspectionId || ""),
		queryFn: () => ok(getInspection(inspectionId || "")),
		// The query will not execute until routeMatch.params.mspId
		enabled: !!inspectionId,
	});

	const photoIds = inspection?.photos || [];
	const getPhotos = useQuery({
		queryKey: [getPhotosQueryKey(inspectionId || "")],
		queryFn: async () =>
			Promise.all(photoIds.map(async (photo) => await getPhoto(photo.id))),
		// The query will not execute until routeMatch.params.mspId
		enabled: !!inspection,
	});

	let photos: JSX.Element[] = [];
	const slidePhotos: SlideImage[] = [];

	if (getPhotos.isSuccess) {
		const photosData = getPhotos.data.map((response) => response.data);
		photos = photosData.map((photoData, i) => {
			let photoUri = "";

			if (typeof photoData === "string") {
				photoUri = `data:image/jpeg;base64,${photoData}`;
				slidePhotos.push({
					src: photoUri,
					download: {
						url: "",
						filename: `${inspectionId}-${i}.jpeg`,
					},
				});
			}

			return (
				<ImageWithOverlay
					key={crypto.randomUUID()}
					src={photoUri}
					onClickHandler={() => {
						if (photoUri !== "") {
							imagePreviewerHandler.open();
							setSelectedImageId(i);
						}
					}}
					size={IMAGE_SIZE}
				/>
			);
		});
	} else {
		photos = photoIds.map(() => (
			<Box
				pos="relative"
				h={IMAGE_SIZE}
				w={IMAGE_SIZE}
				key={crypto.randomUUID()}
			>
				<LoadingOverlay visible />
			</Box>
		));
	}

	if (isPending) {
		return <LoadingOverlay visible />;
	}

	if (!inspection) {
		return <Error />;
	}

	return (
		<>
			<Stack className={classes.container}>
				<Title order={3}>Begehung</Title>
				<Grid gutter={{ base: 28, lg: 60 }}>
					<Grid.Col span={{ base: 12, lg: 6 }}>
						<InputLabel>Standort</InputLabel>
						<Text>{inspection.station.name}</Text>
					</Grid.Col>
					<Grid.Col span={{ base: 12, lg: 6 }}>
						<InputLabel>Datum</InputLabel>
						<Text>
							{humanizedDateTimeFormat(new Date(inspection.createdAt))}
						</Text>
					</Grid.Col>
					<Grid.Col span={12}>
						<InputLabel>Problemanzahl</InputLabel>
						<Text>{inspection.issues.length}</Text>
					</Grid.Col>
					<Grid.Col span={12}>
						<InputLabel>Fotos</InputLabel>
						<Group>{photos}</Group>
					</Grid.Col>
					<Grid.Col span={12}>
						<Group
							justify="flex-end"
							mt={12}
							style={{ flexDirection: isMobile ? "column-reverse" : "row" }}
						>
							<Button
								variant="primary"
								fullWidth={isMobile}
								onClick={() => navigate(inspectionTableRedirect)}
							>
								Zurück
							</Button>
						</Group>
					</Grid.Col>
				</Grid>
			</Stack>
			<Lightbox
				open={isImagePreviewerOpen}
				close={imagePreviewerHandler.close}
				slides={slidePhotos}
				plugins={[Zoom, Download]}
				index={selectedImageId}
				zoom={{
					scrollToZoom: true,
					maxZoomPixelRatio: 3,
				}}
				controller={{ closeOnBackdropClick: true }}
			/>
		</>
	);
}

export default ReadInspection;
