// disable eslint for using props spreading as it's best practices in https://mantine.dev/form/use-form/
/* eslint-disable react/jsx-props-no-spreading */
import React, { ReactElement, useState } from "react";
import { GetInputPropsReturnType } from "@mantine/form/lib/types";
import { Text, Textarea } from "@mantine/core";
import classes from "./DashboardTextarea.module.scss";

type DashboardTextareaProps = {
	formInputProps?: GetInputPropsReturnType;
	label: string;
	placeholder: string;
	withAsterisk?: boolean;
	disabled?: boolean;
};

function DashboardTextarea({
	formInputProps,
	label,
	placeholder,
	withAsterisk = false,
	disabled = false,
}: DashboardTextareaProps): ReactElement {
	const [valueLength, setValueLength] = useState<number>(
		formInputProps?.defaultValue?.length ?? 0
	);

	return (
		<Textarea
			{...formInputProps}
			label={label}
			withAsterisk={withAsterisk}
			placeholder={placeholder}
			autosize
			maxLength={255}
			rightSection={
				<Text size="xs" pr="5px" lh="xs">
					{valueLength}/255
				</Text>
			}
			rightSectionProps={{
				className: disabled
					? `${classes["description-length-indicator"]} ${classes.disabled}`
					: classes["description-length-indicator"],
			}}
			disabled={disabled}
			onChange={(event) => {
				setValueLength(event.target.value.length);
				formInputProps?.onChange(event);
			}}
		/>
	);
}

export default DashboardTextarea;
