import React, { ReactElement } from "react";
import { Layout } from "antd";
import { useIsAuthenticated } from "@azure/msal-react";
import Navbar from "../Navbar/Navbar";
import styles from "./index.module.scss";

function ProtectedRoute({
	children,
}: {
	children: ReactElement;
}): ReactElement {
	const isAuthenticated = useIsAuthenticated();

	if (!isAuthenticated) {
		return <>Wird geladen...</>;
	}

	return (
		<Layout className={styles["default-layout"]}>
			<Layout.Header className={styles["default-header"]}>
				<Navbar />
			</Layout.Header>

			<Layout.Content className={styles["default-content"]}>
				<div className={styles["default-content__inner"]}>{children}</div>
			</Layout.Content>
		</Layout>
	);
}

export default ProtectedRoute;
