import React, { ReactElement } from "react";
import { Link } from "react-router-dom";
import { Group, Stack, Title, Button } from "@mantine/core";
import { IconPlus } from "@tabler/icons-react";
import InspectionsTable from "./InspectionsTable/InspectionsTable";
import classes from "./Inspections.module.scss";

function Inspections(): ReactElement {
	return (
		<Stack className={classes.container}>
			<Group justify="space-between">
				<Title order={3}>Begehungen</Title>
				<Link to="/inspections/add">
					<Button leftSection={<IconPlus />}>Neue Begehung</Button>
				</Link>
			</Group>

			<InspectionsTable />
		</Stack>
	);
}

export default Inspections;
